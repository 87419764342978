import React, { Component, Fragment } from 'react';

import { Context, MetaData, UserContent } from '../components';

class NewsItem extends Component {
	constructor(props) {
		super();
		this.getPost = this.getPost.bind(this);
		let state = {};
		if (!!props.location.state && 'post' in props.location.state)
			state.post = props.location.state.post;
		this.state = state;
	}

	getPost(props) {
		if (!props) props = this.props;
		if ('post' in this.state && props.data.loading)
			return {
				wp_post: this.state.post,
				loading: false,
				error: null,
			};
		return props.data;
	}

	render() {
		const {
			location: { pathname },
			pageContext: { title, content, acf },
		} = this.props;

		return (
			<Context.Consumer>
				{({ setModal }) => (
					<Fragment>
						<MetaData
							title={acf.meta_title || title}
							description={acf.meta_description}
						/>

						<div className="w-container w-container--padded">
							{acf.header_image ? (
								<div className={'article-main-img'}>
									<img
										src={acf.header_image.source_url}
										alt={acf.header_image.alt_text}
									/>
								</div>
							) : null}

							<h2
								className="articleheader center"
								dangerouslySetInnerHTML={{ __html: title }}
							/>

							<div className="w-row">
								<div className="w-col w-col-12">
									<UserContent
										content={content}
										path={pathname}
										openModal={() => setModal(true)}
									/>
									{/*<ContentWithVideos {...{ content, urls }} className="user-content" />*/}
								</div>
							</div>
						</div>
					</Fragment>
				)}
			</Context.Consumer>
		);
	}
}

export default NewsItem;
